/* src/pages/AboutMe.css */

.main-content {
  padding: 2rem 5%;
  color: #fff;
}

.main-content-text {
  max-width: 1000px;
  margin: 0 auto;
  font-size: 1.4rem;
}

.main-content-text h2,
.main-content-text h3 {
  color: orange; 
  margin-bottom: 1rem;
}

.main-content-text p,
.main-content-text ul {
  color: #ccc;
  line-height: 2;
}

.main-content-text ul {
  list-style-type: disc;
  padding-left: 20px;
}

.inline-image {
  width: 200px; 
  height: auto;
  border-radius: 10px;
  margin: 0 0 15px 15px; 
  float: right; 
}

.main-content-text::after {
  content: "";
  display: table;
  clear: both;
}


.experience-entry {
  margin-bottom: 2rem; 
  
}

.experience-entry:last-child {
  margin-bottom: 0;
}


.button-container {
  margin: 20px 0; 
}

.download-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: orange; 
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #cc8400; 
}

@media (max-width: 768px) {
  .inline-image {
    float: none;
    display: block;
    margin: 0 auto 15px;
  }
  .main-content-text {
    font-size: 1.2rem;
  }
}
