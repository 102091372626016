.home-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: space-around; /* Adjusted */
  padding: 0 5%;
  position: relative;
  background-color: #252526;
}

.name-section {
  flex: 1;
}

.my-name {
  font-size: 4rem;
  color: #fff;
}

.links-section {
  flex: 1;
  text-align: right;
}

.home-link {
  display: block;
  font-size: 3.2rem;
  color: #fff;
  text-decoration: none;
  margin-bottom: 1rem;
  transition: color 0.3s;
}

.home-link:hover {
  color: #ccc;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #fff }
}

.intro-text {
  font-size: 1.5rem;
  color: #ccc;
  margin-top: 1rem;
}

.profile-image {
  flex: 1; /* Added */
  margin-top: 2rem;
  text-align: center;
}

.profile-image img {
  width: 200px;
  height: 250px;
  border-radius: 25%;
  object-fit: cover;
}

.typewriter {
  display: inline-block;
  overflow: hidden;
  border-right: .15em solid #fff;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: .15em;
  animation:
    typing 2.5s steps(30, end),
    blink-caret .5s step-end infinite;
}

@media (max-width: 1300px) {
  .my-name {
    font-size: 3rem;
    margin-bottom: 1rem;
    margin-right: 10px;
    text-align: center;
  }

  .intro-text {
    font-size: 1.1rem;
    text-align: center;
  }

  .home-link {
    font-size: 2.2rem;
  }
  .profile-image img {
    width: 180px;
    height: 220px;
  }  
}

/* Adjusted breakpoint from 768px to 1150px */
@media (max-width: 1150px) {
  .home-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 5%;
  }

  .name-section {
    order: 1;
    word-wrap: break-word;
  }

  .profile-image {
    order: 2;
    margin-top: 2rem;
    text-align: center;
  }

  .links-section {
    order: 3;
    margin-top: 2rem;
    text-align: center;
  }

  .my-name {
    font-size: 3.4rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .intro-text {
    font-size: 1.4rem;
    text-align: center;
  }

  .home-link {
    font-size: 2.6rem;
  }

  .profile-image img {
    margin-left: 0;
  }
}

/* Adjust subsequent breakpoints if needed */
@media (max-width: 768px) {
  .my-name {
    font-size: 2rem;
  }

  .intro-text {
    font-size: 1rem;
  }

  .home-link {
    font-size: 1.8rem;
  }

  .profile-image img {
    width: 180px;
    height: 220px;
  }
}

@media (max-width: 480px) {
  .my-name {
    font-size: 1.8rem;
  }

  .intro-text {
    font-size: 0.9rem;
  }

  .home-link {
    font-size: 1.6rem;
  }

  .profile-image img {
    width: 150px;
    height: 180px;
  }
}

@media (max-width: 460px) {
  .my-name {
    font-size: 1.5rem;
    text-align: center;
  }
}
