/* src/styles/styles.css */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Consolas, 'Courier New', monospace;
    color: #F8F8F8;
  }
  
  body {
    background-color: #252526;
  }
  